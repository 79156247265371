<template>
  <table
    class="table table-sm"
    :class="{ 'table-responsive': $store.state.display.sm }"
  >
    <thead>
      <tr>
        <th v-if="!readonly"></th>
        <th>OBSERVACION</th>
        <th>FUENTE</th>
        <th class="text-right">MONTO</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="l in inputs" :key="l.id">
        <td v-if="!readonly">
          <button class="btn btn-light btn-sm" @click="$refs.elPreDel.val = l.id; $refs.elPreDel.show();">
            <i class="fa fa-trash"></i>
          </button>
        </td>
        <td :title="l.id">{{ l.observation }}</td>
        <td>{{ l.source_name }}</td>
        <td class="text-right">
          <app-span-money
            :quantity="l.quantity"
            :moneyCode="l.money_code"
          ></app-span-money>
        </td>
      </tr>
    </tbody>
    
    <app-modal-yn ref="elPreDel" @yes="deleteItem($refs.elPreDel.val);">
      <p>Eliminar este ingreso?</p>
    </app-modal-yn>
  </table>
</template>

<script>
import { AdminService } from "../AdminService";

export default {
  props: {
    readonly: {
      required: true
    },
    inputs: {
      default: []
    }
  },
  methods: {
    deleteItem(id) {
      AdminService.deleteInput(id).then(() => this.$emit("itemDeleted"));
    }
  }
};
</script>

<style></style>
