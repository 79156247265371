<template>
  <form @submit.prevent="guardar()">
    <div class="form-group">
      <label for>Razón *</label>
      <SelectReason v-model="reg.reason_id" ref="selectReason"></SelectReason>
    </div>

    <div
      class="alert alert-info"
      v-if="
        $refs.selectReason &&
          $refs.selectReason.val &&
          $refs.selectReason.val.budget
      "
    >
      Presupuestado:
      <app-span-money
        :quantity="$refs.selectReason.val.budget.max"
        :moneyCode="$refs.selectReason.val.budget.money_code"
      ></app-span-money
      >, monto disponible
      <app-span-money
        :quantity="$refs.selectReason.val.budget.max_current"
        :moneyCode="$refs.selectReason.val.budget.money_code"
      ></app-span-money>
    </div>

    <div class="form-group">
      <label for>Medio de pago *</label>
      <app-select-payment-method
        v-model="reg.payment_method_id"
        ref="selectPayMethod"
      ></app-select-payment-method>
    </div>

    <div class="form-group">
      <label for>Monto *</label>
      <app-input-money
        ref="inputMoney"
        :required="true"
        :quantity.sync="reg.quantity"
        :moneyCode.sync="reg.money_code"
      ></app-input-money>
    </div>

    <div class="form-group">
      <label for>Observación</label>
      <app-textarea v-model="reg.observation" :required="true"></app-textarea>
    </div>

    <div class="form-group">
      <button class="btn btn-primary" type="submit" :disabled="sended">
        Guardar
      </button>
    </div>

    <div class="form-group">
      <app-alert
        class="alert-success"
        msj="Creado con exito"
        :show.sync="saveSuccess"
      ></app-alert>
      <app-alert class="alert-danger" :msj="err" :show.sync="saveError">
        <template slot="msj">
          <strong>Error:</strong>
          {{ err }}
        </template>
      </app-alert>
    </div>
  </form>
</template>

<script>
import SelectReason from "../reasons/Select";
import { AdminService } from "../AdminService";

export default {
  components: {
    SelectReason
    // SelectPayMethod
  },
  data() {
    return {
      reg: {},
      reason: {},
      saveSuccess: false,
      saveError: false,
      sended: false,
      targetUrl: "api/admin/outputs",
      err: ""
    };
  },
  props: {
    cashRegisterId: {
      default: null
    }
  },
  computed: {
    areaId() {
      return this.$store.state.areas.areaId;
    }
  },
  created() {
    if (this.cashRegisterId !== null) {
      this.targetUrl = "api/admin/cash-register/outputs";
    }
  },
  methods: {
    validar() {
      if (!this.reg.reason_id) {
        this.saveError = true;
        this.err = "Razon de egreso invalida";
        return false;
      }
      return true;
    },
    reset() {
      this.reg = {};
      this.saveSuccess = false;
      this.$refs.selectPayMethod.setDefault();
      this.$refs.inputMoney.setDefault();
      this.sended = false;
      this.$refs.selectReason.reset();
    },
    guardar() {
      if (!this.validar()) return;

      this.sended = true;
      if (this.cashRegisterId !== null) {
        this.reg.cash_register_id = this.cashRegisterId;
      }
      AdminService.saveCashRegisterOutput(this.reg).then(
        () => {
          this.saveError = false;
          this.saveSuccess = true;
          this.$emit("submitted");
        },
        err => {
          var res = err.response;
          this.saveError = true;
          this.saveSuccess = false;
          this.sended = false;
          this.err = res.data && res.data.message;
        }
      );
    }
  }
};
</script>

<style>
</style>
