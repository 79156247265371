<template>
  <form @submit.prevent="cambiarRazon()">
    <div class="form-group">
      <label for>Observación</label>
      <textarea
        class="form-control"
        disabled
        rows="3"
        v-model="reg.observation"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="">Nueva Razon</label>
      <SelectReason ref="selectReason" v-model="reg.reason_id"></SelectReason>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" type="submit">Guardar Cambios</button>
    </div>
    <div class="form-group">
      <app-alert
        class="alert-danger"
        v-if="errMsj.length > 0"
        :msj="errMsj"
      ></app-alert>
    </div>
  </form>
</template>

<script>
import SelectReason from "../reasons/Select";
import { AdminService } from "../AdminService";

export default {
  components: {
    SelectReason
  },
  props: {
    cashRegisterId: {
      required: true
    }
  },
  data() {
    return {
      reg: {},
      errMsj: ""
    };
  },
  methods: {
    validar() {
      if (!this.reg.reason_id) return false;
      return true;
    },
    loadReg(reg) {
      this.reg = JSON.parse(JSON.stringify(reg));
      this.$refs.selectReason.reset();
      this.errMsj = "";
    },
    cambiarRazon() {
      if (!this.validar()) return;
      AdminService.saveCashRegisterOutput({
        id: this.reg.id,
        cash_register_id: this.cashRegisterId,
        ...this.reg
      }).then(
        () => {
          this.$emit("submitted");
        },
        err => {
          let res = err.response;
          this.errMsj = res.data.message;
        }
      );
    }
  }
};
</script>

<style>
</style>
