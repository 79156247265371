<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Fuente de Ingreso *</label>
      <SelectSource ref="selectSource" v-model="reg.source_id"></SelectSource>
      <!-- <SourcesSelect :itemId.sync="reg.source_id"></SourcesSelect> -->
    </div>
    <div class="form-group">
      <label for>Medio de pago *</label>
      <app-select-payment-method
        ref="selectPayMethod"
        v-model="reg.payment_method_id"
      >
      </app-select-payment-method>
    </div>
    <div class="form-group">
      <label for>Monto *</label>
      <!-- <ltg-money-input :quantity.sync="reg.quantity" :moneyCode.sync="reg.money_code" :required="true"></ltg-money-input> -->
      <app-input-money
        ref="inputMoney"
        :quantity.sync="reg.quantity"
        :moneyCode.sync="reg.money_code"
        :required="true"
      ></app-input-money>
    </div>
    <div class="form-group">
      <label for>Observacion</label>
      <!-- <textarea class="form-control" rows="3" v-model="reg.observation"></textarea> -->
      <app-textarea v-model="reg.observation"></app-textarea>
    </div>
    <div class="form-group">
      <button :disabled="sended" class="btn btn-primary" type="submit">
        Guardar
      </button>
    </div>
    <div class="form-group">
      <app-alert
        class="alert-success"
        msj="Creado con exito"
        :show.sync="saveSuccess"
      ></app-alert>
      <app-alert class="alert-danger" :msj="err" :show.sync="saveError">
        <template slot="msj"> <strong>Error: </strong> {{ err }} </template>
      </app-alert>
    </div>
  </form>
</template>

<script>
import SelectSource from "../sources/Select";
import { AdminService } from "../AdminService";

export default {
  components: {
    SelectSource
  },
  props: {
    cashRegisterId: {
      required: true
    }
  },
  data() {
    return {
      reg: {},
      saveSuccess: false,
      saveError: false,
      sended: false,
      err: ""
    };
  },
  created() {
    //
  },
  methods: {
    reset() {
      this.reg = {};
      this.saveSuccess = false;
      this.$refs.selectSource.reset();
      this.$refs.selectPayMethod.setDefault();
      this.$refs.inputMoney.setDefault();
      this.sended = false;
    },
    esValido() {
      return true;
    },
    save() {
      if (!this.esValido()) return;
      this.sended = true;
      if (this.cashRegisterId !== null) {
        this.reg.cash_register_id = this.cashRegisterId;
      }
      AdminService.saveCashRegisterInput(this.reg).then(
        () => {
          this.saveError = false;
          this.saveSuccess = true;
          this.$emit("submitted");
        },
        err => {
          var res = err.response;
          this.saveError = true;
          this.sended = false;
          this.err = res.data.message ? res.data.message : res.data;
        }
      );
    }
  }
};
</script>
<style></style>
