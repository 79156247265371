<template>
  <table
    class="table table-sm"
    :class="{ 'table-responsive': $store.state.display.sm }"
  >
    <thead>
      <tr>
        <slot name="thead"></slot>
        <th>OBSERVACION</th>
        <th>RAZON</th>
        <th class="text-right">MONTO</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="l in outputs" :key="l.id">
        <slot name="tbody" :reg="l"></slot>
        <td :title="l.id">{{ l.observation }}</td>
        <td>{{ l.reason_name }}</td>
        <td class="text-right">
          <app-span-money
            :quantity="l.quantity"
            :moneyCode="l.money_code"
          ></app-span-money>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    outputs: {
      default: () => []
    }
  }
};
</script>

<style>
</style>
